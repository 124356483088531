module.exports = {
  corporationName: 'Nitesh Sood',
  siteTitle: 'Nitesh Sood',
  siteDescription: 'Full Stack Software Developer at Khoros, BITS Pilani Goa - MSc. Maths & B.E. Computer Science, a Fitness freak',
  siteKeywords: 'software, developer, react, react-native, full stack, java',
  siteUrl: 'https://www.niteshsood.com',
  siteLogo: '/images/logo_for_schema.png',
  googleAnalyticsID: 'UA-174158133-1',
  siteAuthor: 'Nitesh Sood',
};
